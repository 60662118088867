import React from "react";
import { useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const LocationPrompt = () => {
  const [open, setOpen] = React.useState(false);

  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect ( ()=>{
    handleClickOpen()

  }, [])

  return (
    <div>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Allow Location Access</DialogTitle>
        
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          You need to click allow to share your location
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        
          <Button onClick={handleClose}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
   
    </div>
  );
};

export default LocationPrompt;
