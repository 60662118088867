import React from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { Autocomplete, TextField } from "@mui/material";

import "./map.css";
import {
  removeCurrMarker,
  resetPosition,
  updateCenter,
  updateMarker,
} from "./MapHolder";

import { mobile } from "../../App";

const SearchBar = () => {
  const options = {
    requestOptions: {
      componentRestrictions: { country: "bd" },
      fields: ["address_components", "geometry", "icon", "name"],
      types: ["establishment"],
    },
  };
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete(options);
  const handleSelect = async (address, reason) => {
    // console.log(address);

    if (reason === "selectOption") {
      setValue(address, false);
      clearSuggestions();

      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      updateCenter({ lat, lng });
      updateMarker({ lat, lng });
    }
  };

  const handleInputChange = (event) => {
    setValue(event.target.value);
    if (event.target.value === "") {
      resetPosition();
      removeCurrMarker();
    }
  };

  return (
    <div>
      {!mobile ? (
        <Autocomplete
          disabled={!ready}
          style={{ backgroundColor: "white" }}
          className={"search"}
          sx={{
            position: "absolute",
            top: "2em",
            padding: "1.5em",
            borderRadius: ".5em",
            transform: "translate(-50%, 0%)",
            width: "50vw",
            left: "50%",
            boxShadow: "0px 8px 8px rgba(86, 92, 108, 0.06)",
          }}
          freeSolo
          disableClearable
          onInputChange={handleInputChange}
          // onChange={(_, address) => handleSelect(address)}
          onChange={(_, address, reason) => handleSelect(address, reason)}
          options={data.map(({ _, description }) => description)}
          renderInput={(params) => (
            <TextField
              className={"searchText"}
              {...params}
              label="Search location..."
              InputProps={{
                ...params.InputProps,
                type: "search",
              }}
            />
          )}
        />
      ) : (
        <div>
          <Autocomplete
            disabled={!ready}
            style={{ backgroundColor: "white" }}
            className={"search"}
            sx={{
              position: "absolute",
              top: "6em",
              left: "50%",
              padding: "2.5%",
              transform: "translate(-50%, -50%)",
              borderRadius: ".25em",
              width: "95%",
              boxShadow: "0px 8px 8px rgba(86, 92, 108, 0.06)",
            }}
            freeSolo
            disableClearable
            onInputChange={handleInputChange}
            onChange={(_, address) => handleSelect(address)}
            options={data.map(({ _, description }) => description)}
            renderInput={(params) => (
              <TextField
                className={"searchText"}
                {...params}
                label="&nbsp;&nbsp;&nbsp;&nbsp;Search location..."
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

export default SearchBar;
