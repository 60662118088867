import React, {useState} from 'react'
import {Grid} from "@mui/material";
import MapHolder from '../map/MapHolder';

const Home = props => {

    return(
        <div>
            <MapHolder/>
        </div>
    )

}


export default Home