import { Message } from '@mui/icons-material'
import { Button } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { useNavigate } from 'react-router'

const InfoPage = () => {
    const navigate = useNavigate()
    const md = `# EWS-RE INFO PAGE`
    return (
        <div>
            <center>
                <br/>
                <br/>
        <h2>About EWS-RE</h2>
        
        <div className='default-box'>
           
        River Erosion is a long-term disaster in Bangladesh, causing thousands of people to become impoverished and homeless, forcing them 
to migrate to cities and live in abject poverty. On the other hand, it is a natural river adjustment process that is necessary for the river 
and its ecosystem. Therefore, for sustainability, we must balance our rivers and human needs. In this case, Early Warning can be a 
scientific solution. With the support of the ICT division, Government of Bangladesh this two-year long research aims to create a web-based early warning system for one of the major river’s erosion specially for the charlands and riverbanks using numerical models and 
satellite images.
        <br/>
        <br/>
        <b>Principal Investigator:</b>
        <br/>
        Dr. Shampa, Associate Professor, Institute of Water and Flood Management (IWFM), BUET
        <br/>
        <br/>
        <b>Research Team:</b>
        <br/>
        Hussain Muhammad Muktadir, Institute of Water and Flood Management (IWFM), BUET
        <br/>
        Israt Jahan Nejum, Institute of Water and Flood Management (IWFM), BUET
        <br/>
        Anup Bhowmik, Department of Computer Science and Engineering, BUET
        <br/>
        Nazmul Islam Ananto, Department of Computer Science and Engineering, BUET
        <br/>
        Showvik Biswas, Department of Computer Science and Engineering, BUET
        <br/>
        Fardin Anam Aungon, Department of Computer Science and Engineering, BUET
        <br/>
        Mohammad Muddassir Islam, Institute of Water and Flood Management (IWFM), BUET
        <br/>
        </div>

        {/* <Button variant='contained' onClick={()=>{navigate("/")}}>Back to map</Button> */}
        </center>
        </div>
    )
}

export default InfoPage