import { useEffect, useState } from "react";
import { showToast } from "../../App";
import { updateCenter } from "../map/MapHolder";

var currLocation
var setLocation

var updateLoc

const UseGeoLocation = (getCurrLocation) => {
    [currLocation, setLocation] = useState({
        loaded: false,
        supported: false,
        denied: true,

        coordinates: {
            lat: 25.252283,
            lng: 89.658375,
        },
    })

    updateLoc = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(onSuccess, onError);

        } else {
            alert("Sorry, your browser does not support GPS");
            setLocation({ loaded: true, supported: false })
        }

    }
    const onSuccess = loc => {
        var locationData = {
            loaded: true,
            supported: true,
            coordinates: { lat: loc.coords.latitude, lng: loc.coords.longitude }
        }
        setLocation(
            locationData
        )

        // console.log(locationData)
        if (getCurrLocation) { updateCenter(locationData.coordinates) }
        else {
            // default location of Jamuna 

            updateCenter({
                lat: 25.252283,
                lng: 89.658375,
            })
        }

        // showToast("GPS Enabled")
    }

    function onError(error) {
        setLocation({ loaded: true })
        if (error.code === 1) {
            setLocation({ loaded: true, denied: true })
            // showToast("GPS access Denied")

        } else if (error.code === 2) {
            showToast("Network unavailable, GPS cannot be reached.")

        } else if (error.code === 3) {
            showToast("Timed out")

        } else {
            alert("unknown error")

        }
    }

    useEffect(() => {
        // don't show the prompt to enable GPS if the site already has permission to access GPS
        if (navigator.permissions) {
            navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
                if (result.state === 'granted') {
                    setLocation({ loaded: true, supported: true })
                    updateLoc()
                } else if (result.state === 'prompt') {
                    setLocation({ loaded: true, supported: true })
                    updateLoc()
                } else if (result.state === 'denied') {
                    setLocation({ loaded: true, supported: true, denied: true })
                }
                result.onchange = function () {
                    if (result.state === 'granted') {
                        setLocation({ loaded: true, supported: true })
                        updateLoc()
                    } else if (result.state === 'prompt') {
                        setLocation({ loaded: true, supported: true })
                        updateLoc()
                    } else if (result.state === 'denied') {
                        setLocation({ loaded: true, supported: true, denied: true })
                    }
                }
            })
        } else {
            setLocation({ loaded: true, supported: false })
        }

        
        

        // if (navigator.geolocation) {
        //     navigator.geolocation.getCurrentPosition(onSuccess, onError);

        // } else {
        //     alert("Sorry, your browser does not support GPS");
        //     setLocation({ loaded: true, supported: false })
        // }

    }, [])

}

export default UseGeoLocation
export { currLocation, updateLoc }