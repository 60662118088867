import './App.css';
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import axios from 'axios';

import {
  Routes,
  Route
} from "react-router-dom";
import Home from './components/home/Home';
import InfoPage from './components/info/InfoPage';

var mobile
var setMobile
var showToast
function App() {

  [mobile, setMobile] = useState(false)

  function handleResize() {
    if (window.innerWidth <= 1020) setMobile(true);
    else setMobile(false);
  }

  useEffect(() => {
    if (window.innerWidth <= 1020) setMobile(true);
    else setMobile(false);
    window.addEventListener("resize", handleResize);
  }, []);

  showToast = (message) => {
    toast.info(message, {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
    });
  };
  return (
    <div>
      <ToastContainer
          position="bottom-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
      />
      
      <Routes>
        <Route exact path="/" element = {<Home/>}/>
        <Route exact path="/info" element = {<InfoPage />}/>
      </Routes>
      
    </div>
  );
}

export default App;
export { showToast, mobile, setMobile }
