import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Button, Checkbox } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { warning } from "@remix-run/router";
import LocationOnIcon from "@mui/icons-material/FmdGood";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const navigate = useNavigate();
  const { window } = props;
  const initWData = props.wData;
  const warningData = props.warningData;
  const setWarningData = props.setWarningData;
  const markers = props.markers;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [lowChecked, setLowChecked] = useState(false);
  const [medChecked, setMedChecked] = useState(false);
  const [highChecked, setHighChecked] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleHighChecked = () => {
    setHighChecked(!highChecked);
  };

  const handleMedChecked = () => {
    setMedChecked(!medChecked);
  };

  const handleLowChecked = () => {
    setLowChecked(!lowChecked);
  };

  useEffect(() => {
    if (lowChecked) {
      // activated
      const filtered = initWData.filter((element) => {
        return element.z === 0;
      });
      const newData = warningData.concat(filtered);
      setWarningData(newData);
    } else {
      // deactivated
      // markers.map((element) => {
      //   element.setMap(null);
      // });
      // markers.length = 0;
      const filtered = warningData.filter((element) => {
        return element.z !== 0;
      });
      setWarningData(filtered);
      // console.log(filtered);
    }
  }, [lowChecked]);

  useEffect(() => {
    if (medChecked) {
      // activated
      const filtered = initWData.filter((element) => {
        return element.z === 1;
      });
      const newData = warningData.concat(filtered);
      setWarningData(newData);
    } else {
      // deactivated
      // markers.map((element) => {
      //   element.setMap(null);
      // });
      // markers.length = 0;
      const filtered = warningData.filter((element) => {
        return element.z !== 1;
      });
      setWarningData(filtered);
      // console.log(filtered);
    }
  }, [medChecked]);

  useEffect(() => {
    if (highChecked) {
      // activated
      const filtered = initWData.filter((element) => {
        return element.z === 2;
      });
      const newData = warningData.concat(filtered);
      setWarningData(newData);
      // console.log(newData);
    } else {
      // deactivated
      // markers.map((element) => {
      //   element.setMap(null);
      // });
      // markers.length = 0;
      const filtered = warningData.filter((element) => {
        return element.z !== 2;
      });
      setWarningData(filtered);
    }
  }, [highChecked]);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const drawer = (
    <div>
      {/* <Toolbar /> */}
      <h3 className='tiro-bangla-regular' style={{ paddingLeft: "16px", paddingBottom: "0", marginTop: "24px", marginBottom: "0" }}>নদী ভাঙন ঝুঁকিপ্রবণ এলাকা</h3>
      <h3 style={{ paddingLeft: "16px", margin: "0" }}>River Erosion Risk</h3>
      <Divider />
      <List>
        <ListItem>
          <Checkbox
            sx={{
              color: "#ff050e",
              "&.Mui-checked": {
                color: "#ff050e",
              },
            }}
            {...label}
            defaultChecked={false}
            onChange={handleHighChecked}
          />
          <span className="dot" style={{ backgroundColor: "red" }}></span>
          <p className="dottext" style={{ paddingLeft: "10px", color: "red" }}>
            High
          </p>
        </ListItem>
        <ListItem>
          <Checkbox
            sx={{
              color: "#fccf03",
              "&.Mui-checked": {
                color: "#fccf03",
              },
            }}
            {...label}
            defaultChecked={false}
            onChange={handleMedChecked}
          />
          <span className="dot" style={{ backgroundColor: "#fccf03" }}></span>
          <p
            className="dottext"
            style={{ paddingLeft: "10px", color: "#fccf03" }}
          >
            Moderate
          </p>
        </ListItem>
        <ListItem>
          <Checkbox
            sx={{
              color: "#1fb862",
              "&.Mui-checked": {
                color: "#1fb862",
              },

            }}
            {...label}
            defaultChecked={false}
            onChange={handleLowChecked}
          />
          <span className="dot" style={{ backgroundColor: "#1fb862" }}></span>
          <p
            className="dottext"
            style={{ paddingLeft: "10px", color: "#1fb862" }}
          >
            Low
          </p>
        </ListItem>
        {/* {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))} */}
      </List>
      <Divider />

      <List>
        <ListItem>
          {/* <span className="dot"></span> */}
          <LocationOnIcon style={{ color: "red" }} /> &nbsp;
          <p>Your current location</p>
        </ListItem>
        <ListItem>
          <LocationOnIcon style={{ color: "#7ac943" }} /> &nbsp;
          <p>Your searched location</p>
        </ListItem>


      </List>
      {/* <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"

          sx={{

            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>




  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
