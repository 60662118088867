import React, { useState, useMemo, useEffect } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import SearchBar from "./SearchBar";
import Sidebar from "./Sidebar";
import RedDot from "../assets/red-dot.png";
import GreenDot from "../assets/green-dot.png";
import YellowDot from "../assets/yellow-dot.png";
import ApiFile from "../../api/response.json";
import SearchedPos from "../assets/searchedPos.png";
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop, CssBaseline, Grid, IconButton } from "@mui/material";
import "./map.css";
import { Button } from "@mui/material";
import GpsFixedOutlinedIcon from "@mui/icons-material/GpsFixedOutlined";
import UseGeoLocation, {
  currLocation,
  updateLoc,
} from "../utils/UseGeoLocation";
import { mobile, showToast } from "../../App";
import SidebarMobile from "../mobile/SidebarMobile";
import LocationPrompt from "../dialog/LocationPrompt";
import MachineLearningData from "../model/MachineLearningData";
import ResponsiveDrawer from "./ResponsiveDrawer";
import SwipeableTemporaryDrawer from "./SwipeableDrawer";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';



// var location;

var updateCenter;
var updateMarker;
var removeCurrMarker;
var resetPosition;


const MapHolder = (props) => {
  UseGeoLocation(false);


  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCQVOx7U3V7q5yEpvMAiFkx6sHMI791bzA",
    libraries: ["places"],
  });
  // might want to put the api key to .env file and then load this env wherever we host the site

  if (!isLoaded) {
    return (
      <div>
        <CircularProgress sx={{ marginTop: "40%", marginLeft: "50%" }} />
      </div>
    );
  }

  return (
    <div>
      <Map />
      <SearchBar />
    </div>
  );
};


const Map = () => {
  const [loading, setLoading] = useState(false);

  const [defaultLocation, setDefaultLocation] = useState({
    lat: 25.252283,
    lng: 89.658375,
  });

  const [data, setData] = useState("");

  const zoomValue = 12;
  // var centerPos = useMemo(() => location.coordinates, []);

  const [map, setMap] = React.useState(null);
  // const [wData, setWData] = useState([]);
  const [warningData, setWarningData] = useState([]);
  // const [markers, setMarkers] = useState([]);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  var marker;

  removeCurrMarker = () => {
    if (marker != null) {
      marker.setMap(null);
    }
  };

  updateMarker = (pos) => {
    //Remove previous Marker.
    removeCurrMarker();

    marker = new window.google.maps.Marker({
      position: pos,
      map,
      icon: SearchedPos,
    });
  };

  const addWarning = (pos, color) => {
    marker = new window.google.maps.Marker({
      position: pos,
      map,
      icon: {
        url: color,
        scaledSize: new window.google.maps.Size(20, 20),
      },
    });
    return marker;
  };

  updateCenter = (pos) => {
    if (map == null) return;
   
    map.panTo(pos);
  };

  const high_link = 'https://ews-rd-dev.s3.amazonaws.com/Jamuna_2023_High_Erosion_Zone.json?alt=media&token=342342'
  // const high_link = 'https://firebasestorage.googleapis.com/v0/b/ews-re-cfb66.appspot.com/o/levels%2F15.39888659738030.json?alt=media'
  const low_link = 'https://ews-rd-dev.s3.amazonaws.com/Jamuna_2023_Low_Erosion_Zone.json'
  const medium_link = 'https://ews-rd-dev.s3.amazonaws.com/Jamuna_2023_Moderate_Erosion_Zone.json'


  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(centerPos);
    // map.fitBounds(bounds);


    // colorize the shaded area
    map.data.setStyle(function (feature) {
      var color = "#ff0000";
      if (feature.getProperty("isColorful")) {
        color = feature.getProperty("color");
      }
      return {
        fillColor: color,
        strokeColor: color,
        strokeWeight: 1,
      };
    });

    map.data.loadGeoJson(data);

    setMap(map);
    map.setMapTypeId(window.google.maps.MapTypeId.HYBRID);

    if (currLocation?.loaded && !currLocation?.denied) {
      // map.panTo(centerPos);
      // map.panTo(currLocation?.coordinates);
      map.panTo(defaultLocation);
    } else {
      map.panTo(defaultLocation);
    }


  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const [rePrompt, setRePrompt] = useState(false);

  useEffect(() => {
    updateLoc();
  }, [rePrompt]);

  useEffect(() => {
    if (map == null) return;

    setLoading(true);
    axios.get("https://ews-re-back.onrender.com/link").then((response) => {
      map.data.loadGeoJson(response.data.url);
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setLoading(false);
    });

  }, [map])

  resetPosition = () => {
    // center can be changed after searching
    if (currLocation?.denied) {
      // rePrompt();
      setRePrompt(!rePrompt);
    } else {
      map.panTo(currLocation?.coordinates);
    }
  };

  return (
    <div>
      <CssBaseline />
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    
      {currLocation?.denied ? <LocationPrompt /> : <></>}
      <div>
        <SwipeableTemporaryDrawer

          warningData={warningData}
          setWarningData={setWarningData}
          state={state}
          setState={setState}
          toggleDrawer={toggleDrawer}
          setData={setData}
          map={map}
        ></SwipeableTemporaryDrawer>
      </div>
      <div>
        <GoogleMap style={{ height: "100vh", width: "100vw" }}
          mapContainerClassName="map-container"
          zoom={zoomValue}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={function (maps) {
            return { mapTypeId: "satellite" };
          }}
        >
          {/* Marker is correctly placed on the current location, not on the default location in Jamuna river */}
          {currLocation?.loaded && !currLocation?.denied ? (
            <MarkerF position={currLocation?.coordinates}></MarkerF>
          ) : (
            <></>
          )}
        </GoogleMap>
      </div>

      {!mobile ? (
        <Button
          sx={{
            position: "absolute",
            top: "85%",
            padding: "1.25em",
            borderRadius: ".5em",
            // left: "calc(240px + 0.5 * (100% - 240px))",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
          variant="contained"
          onClick={resetPosition}
        >
          <GpsFixedOutlinedIcon />
          &nbsp;&nbsp;
          {currLocation?.denied && !currLocation?.supported ? (
            <div>Give Location Access</div>
          ) : (
            <div>Your Location <br />আপনার অবস্থান</div>
          )}
        </Button>
      ) : (
        <Button
          sx={{
            position: "absolute",
            top: "70%",
            padding: "1.25em",
            borderRadius: ".5em",
            // left: "calc(240px + 0.5 * (100% - 240px))",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
          variant="contained"
          onClick={resetPosition}
        >
          <GpsFixedOutlinedIcon />
          &nbsp;&nbsp;
          {currLocation?.denied && !currLocation?.supported ? (
            <div>Give Location Access</div>
          ) : (
            <div>Your Location <br />আপনার অবস্থান</div>
          )}
        </Button>

      )}
    </div>
  );
};

export default MapHolder;
export { updateCenter, updateMarker, removeCurrMarker, resetPosition };
